import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"

import ogImage from "@/assets/images/seo/ogimageFr.jpg"
import Footer from "@/components/Footer/Footer"
import Layout from "@/components/Layout/Layout"
import NavbarReplay from "@/components/NavbarReplay/NavbarReplay"
import ReplayIntroSection from "@/components/ReplayIntroSection/ReplayIntroSection"
import ReplaySection from "@/components/ReplaySection/ReplaySection"
import Seo from "@/components/Seo/Seo"
import fr from "@/intl/fr"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollToPlugin)
}

const ReplayPage = ({ data }) => {
  const speakerImages = data.allFile.edges
  const spearkersSectionRef = useRef(null)
  const formRef = useRef(null)
  const retroSectionRef = useRef(null)
  const [showNav, seShowNav] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = window.pageYOffset
      let windowSize = window.innerHeight
      if (currentPosition > windowSize) {
        seShowNav(true)
      } else {
        seShowNav(false)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [showNav])

  const scrolltoConf = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: spearkersSectionRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  const scrolltoRetro = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: retroSectionRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  return (
    <IntlProvider locale="fr" messages={fr}>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="0njh37hxzi11zz2kg5f3wem68cb7xu"
        />
      </Helmet>
      <Seo
        title="Content Summit 2022 | Le rendez-vous 100% digital du content marketing"
        desc="Le Content Summit est LE rendez-vous immanquable des équipes comm et marketing, organisé par PlayPlay."
        ogImage={ogImage}
      />
      <Layout>
        {showNav && <NavbarReplay />}
        <ReplayIntroSection
          scrolltoConf={scrolltoConf}
          scrolltoRetro={scrolltoRetro}
          formRef={formRef}
        />
        <ReplaySection
          scrolltoConf={scrolltoConf}
          speakerImages={speakerImages}
          spearkersSectionRef={spearkersSectionRef}
        />
        <Footer />
      </Layout>
    </IntlProvider>
  )
}

export default ReplayPage

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "images/speakers" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER }
              width: 720
            )
          }
        }
      }
    }
  }
`

ReplayPage.propTypes = {
  data: PropTypes.object,
}
