import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "react-intl"

import NavbarLogo from "@/svgs/NavbarLogo/NavbarLogo"

import * as styles from "./NavbarReplay.module.scss"

const NavbarReplay = ({}) => {
  const intl = useIntl()

  return (
    <div className={styles.NavbarReplayComponent}>
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <NavbarLogo />
        </div>
        <div className={styles.anchorRight}>
          <div>
            <a
              className={styles.cta}
              href={intl.formatMessage({ id: "pp-signup-url" })}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "try-it-free" })}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarReplay

NavbarReplay.propTypes = {
  scrolltoConf: PropTypes.func,
  scrolltoForm: PropTypes.func,
  scrolltoRetro: PropTypes.func,
}
