// extracted by mini-css-extract-plugin
export var ReplayIntroSectionComponent = "ReplayIntroSection-module--ReplayIntroSectionComponent--evU1j";
export var otherLanguage = "ReplayIntroSection-module--otherLanguage--de-n5";
export var wrapper = "ReplayIntroSection-module--wrapper--stgYl";
export var left__wrapper = "ReplayIntroSection-module--left__wrapper--0nc1+";
export var navButtons = "ReplayIntroSection-module--navButtons--YhPUe";
export var speakers_button = "ReplayIntroSection-module--speakers_button--b3sUn";
export var right__wrapper = "ReplayIntroSection-module--right__wrapper--rfP+Y";
export var virtual_event = "ReplayIntroSection-module--virtual_event--wL7eY";
export var free_event = "ReplayIntroSection-module--free_event--wSVL-";
export var title_precisedate = "ReplayIntroSection-module--title_precisedate--ilM78";
export var title_description = "ReplayIntroSection-module--title_description--FgIdi";
export var tags = "ReplayIntroSection-module--tags--YqOhj";