import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import alexis from "@/assets/images/replays/BLOG - Replays FR Alexis Chevallier.jpg"
import antoine from "@/assets/images/replays/BLOG - Replays FR Antoine Filliaudeau.jpg"
import arnaud from "@/assets/images/replays/BLOG - Replays FR Arnaud Maillard.jpg"
import caroline from "@/assets/images/replays/BLOG - Replays FR Caroline Mignaux.jpg"
import clara from "@/assets/images/replays/BLOG - Replays FR Clara Landecy.jpg"
import guilhem from "@/assets/images/replays/BLOG - Replays FR Guilhem Bertolet.jpg"
import jeremie from "@/assets/images/replays/BLOG - Replays FR Jérémie Amram.jpg"
import nina from "@/assets/images/replays/BLOG - Replays FR Nina Ramen.jpg"
import thibaut from "@/assets/images/replays/BLOG - Replays FR Thibaut Machet.jpg"
import useWindowSize from "@/hooks/UseWindowSize"

import * as styles from "./ReplaySection.module.scss"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const ReplaySection = ({ spearkersSectionRef }) => {
  const sectionRef = useRef(null)
  const titleRef = useRef(null)
  const lightMaskRef = useRef(null)
  const [lightMaskSize, setLightMaskSize] = useState([0, 0])
  const intl = useIntl()

  const size = useWindowSize()

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
    const titlePosition = titleRef.current.getBoundingClientRect()
    gsap.to(lightMaskRef.current, {
      r: 100,
      attr: {
        cx: titlePosition.x + titleRef.current.clientWidth / 2,
        cy: 200,
      },
      opacity: 0,
      duration: 0,
    })

    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: "top top",
      end: "top top",
      // markers: true,
      onEnter: () => {
        let tl = gsap.timeline()
        tl.to(lightMaskRef.current, {
          r: 400,
          opacity: 1,
          duration: 0.2,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
      },
      onLeaveBack: () => {
        gsap.to(lightMaskRef.current, {
          r: 100,
          opacity: 0,
          duration: 0.2,
        })
      },
    })
  }, [])

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
  }, [size])

  return (
    <div className={styles.ReplaySectionComponent} ref={sectionRef}>
      <div className={styles.wrapper} ref={spearkersSectionRef}>
        <div className={styles.title__wrapper}>
          <h2 className={styles.title} ref={titleRef}>
            <FormattedMessage id="replay2022" />
          </h2>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.speakerCardWrapper}>
          <a
            href="https://www.youtube.com/watch?v=Ob1f6dt8ECk"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={jeremie} alt="Jérémie Amram" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=Bf44nsJf39E"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={clara} alt="Clara Landecy" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=cInxw_YW1BY"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={caroline} alt="Caroline Mignaux" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=5EBY5elY_nI"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={guilhem} alt="Guilhem Bertolet" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=5wz935xrols"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={nina} alt="Nina Ramen" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=s8m6hw5bYvg"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={arnaud} alt="Arnaud Maillard" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=pceDHUPOKh0"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={thibaut} alt="Thibaut Machet" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=EIcf0l78tuQ"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={antoine} alt="Antoine Filliaudeau" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=1hjdOcGAjog"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={alexis} alt="Alexis Chevallier" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ReplaySection

ReplaySection.propTypes = {
  speakerImages: PropTypes.array,
  spearkersSectionRef: PropTypes.object,
}

// SpeakerSection.defaultProps = {}
